import { ref } from 'vue';
import { useLocalStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { useApi } from '@/composables/useApi';
import { format, parseISO } from 'date-fns';

import useStaffStore from '@/stores/agent/agent-staff';
import { mockSchedule, emptyCoverageView } from '@/utils/mockAppointmentSchedulerData';

export default defineStore('agent/appointments', () => {
	const hasGreg = useLocalStorage('hasGreg', false);
	const availableAppointmentLengths = ref([]);
	const meetingTypes = ref([]);
	const weeklyScheduleChanges = ref([]);
	const apptsAreLoading = ref(false);
	const schedulesAreLoading = ref(true);
	const schedules = ref([]);
	const appointments = ref({
		upcoming: [],
		previous: [],
	});
	const calendarSubscriptionURL = ref('');

	async function getAppointments() {
		apptsAreLoading.value = true;
		const result = await useApi(`appointments/`, {
			v3Agent: true,
			message: `There was an error processing the request. Please try again later.`,
		}).json();

		const upcomingAppointmentDayMap = {};
		const upcomingDays = [];
		const previousAppointmentDayMap = {};
		const previousDays = [];

		// depends on result.data.value being an _ordered_ list of appointments.
		for (const appointment of result.data.value) {
			const date_label = format(parseISO(appointment.start_at), 'EEEE, MMMM do, yyyy');
			if (appointment.has_passed) {
				if (
					previousDays.length == 0 ||
					previousDays[previousDays.length - 1] != date_label
				) {
					previousDays.push(date_label);
				}
				if (!(date_label in previousAppointmentDayMap)) {
					previousAppointmentDayMap[date_label] = {
						day: date_label,
						appointments: [],
					};
				}
				previousAppointmentDayMap[date_label].appointments.unshift(appointment);
			} else {
				if (
					upcomingDays.length == 0 ||
					upcomingDays[upcomingDays.length - 1] != date_label
				) {
					upcomingDays.push(date_label);
				}
				if (!(date_label in upcomingAppointmentDayMap)) {
					upcomingAppointmentDayMap[date_label] = {
						day: date_label,
						appointments: [],
					};
				}
				upcomingAppointmentDayMap[date_label].appointments.unshift(appointment);
			}
		}

		const upcomingAppointments = upcomingDays
			.map(day => upcomingAppointmentDayMap[day])
			.reverse();
		const previousAppointments = previousDays.map(day => previousAppointmentDayMap[day]);
		appointments.value = {
			upcoming: upcomingAppointments,
			previous: previousAppointments,
		};
		// appointments.value = mockAppts;
		apptsAreLoading.value = false;
	}

	async function getSchedules() {
		schedulesAreLoading.value = true;
		const result = await useApi(`schedules/`, {
			v3Agent: true,
			message: `We haven't built the Greg API for schedules yet! Please try again later.`,
		}).json();
		schedules.value = result.data?.value;
		schedules.value = [mockSchedule];
		schedulesAreLoading.value = false;
	}

	// There's no mock data for this yet, so it's staying commented out so tests can pass.
	// async function getSchedule({ pk }) {
	// 	const result = await useApi(`schedules/${pk}/`, {
	// 		v3Agent: true,
	// 		message: `We haven't built the Greg API yet! Please try again later.`,
	// 	}).json();

	// 	return result.data?.value ?? null;
	// }

	async function createSchedule() {
		const uid = crypto.randomUUID().slice(0, 3);
		const newSchedule = {
			name: `New Schedule ${uid}`,
			id: uid,
			domains: [],
			calendarSubscriptionURL: `https://mysfdomain.com/api/v3/agents/0XDEADBEEFD/appointments/calendar/${uid}.ics`,
			availabilityBlocks: {},
			timeOff: [],
			coverageView: emptyCoverageView,
		};

		const staffStore = useStaffStore();
		for (const staffMember of staffStore.staff) {
			newSchedule.availabilityBlocks[staffMember.associate_id] = [];
		}

		schedules.value.push(newSchedule);
	}

	async function signUpForAppointmentScheduling() {
		hasGreg.value = true;
	}

	async function cancelAppointmentScheduling() {
		hasGreg.value = false;
	}

	async function addWeeklyScheduleChange(change) {
		return change;
	}

	async function removeWeeklyScheduleChange(change) {
		return change;
	}

	async function addSpecificDateOverride(override) {
		return override;
	}

	async function removeSpecificDateOverride(override) {
		return override;
	}

	async function updateAvailableAppointmentLengths() {}

	async function updateMeetingTypes() {}

	async function toggleSchedulingForDomain(/*domain*/) {
		// const response = await useApi(`domains/${domain.domain_name}/`, {
		// 	v3Agent: true,
		// 	message: `We haven't built the Greg API yet! Please try again later.`,
		// }).put({ ...domain, apptSchedulerEnabled: !domain.apptSchedulerEnabled });

		// return response.data?.value ?? { success: false };

		// todo: remove this mock response & restore the api call
		return new Promise(resolve => {
			setTimeout(() => {
				resolve([mockSchedule]);
			}, 1500);
		});
	}

	async function cancelAppointment(id) {
		window.alert(`Cancel appointment with ID: ${id}`);
	}

	async function rescheduleAppointment(id) {
		window.alert(`Reschedule appointment with ID: ${id}`);
	}

	return {
		hasGreg,
		availableAppointmentLengths,
		meetingTypes,
		weeklyScheduleChanges,
		apptsAreLoading,
		schedulesAreLoading,
		schedules,
		appointments,
		calendarSubscriptionURL,

		getAppointments,
		getSchedules,
		// getSchedule,
		createSchedule,
		signUpForAppointmentScheduling,
		cancelAppointmentScheduling,
		addWeeklyScheduleChange,
		removeWeeklyScheduleChange,
		addSpecificDateOverride,
		removeSpecificDateOverride,
		updateAvailableAppointmentLengths,
		updateMeetingTypes,
		toggleSchedulingForDomain,
		cancelAppointment,
		rescheduleAppointment,
	};
});
